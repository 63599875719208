import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "crazywinners2",
  casinoId: 217,
  GA_TRACKING:'G-W685MSG9PE',

  rivalChatGroupName: "CRAZYWINNERS",
  prettyName: "Crazy Winners",
  contactEmail: "support@crazywinners.com",
  docsEmail: "documents@crazywinners.email",

  assetsPath: "assets/brands/crazywinners",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/crazywinners/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/crazywinners/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/crazywinners/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/crazywinners/promo.png"}
  ],


  //SEO
  metaDescription: "Play Online Casino crazy at Crazy Winners Casino with crazy slots, premium online dealers, jackpot machines, and bingo slots. Generous deposit bonuses, a rewarding VIP loyalty program, and secure mobile gaming. Bitcoin Payment accepted. Fair play at the top of online gambling industry leaderboard!",
  logoName: "crazy-winners-online-casino-logo.png",
  logoAltText: "Crazy Winners online casino logo with bold yellow and white lettering featuring a unique crown icon popular for slots games and gambling bonuses."
};


